import React, { useState, useEffect } from 'react';

// Countdown component
const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = targetDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div>
      {Object.keys(timeLeft).length === 0 ? (
        <p>Happy Christmas! 🎄</p>
      ) : (
        <p>
          Hurry! Only {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s left until New Year Day!
        </p>
      )}
    </div>
  );
};

const Christmas = () => {
  // Target date for Christmas Day (December 25th, 2024)
  const christmasDate = new Date("2024-12-31T00:00:00");

  return (
    <div style={styles.page}>
      <header style={styles.header}>
        <h1 style={styles.title}>Happy Christmas from Shoreham Drugs!</h1>
      </header>
      <section style={styles.content}>
        {/* Left Side: Text and Offer */}
        <div style={styles.leftContent}>
          <p style={styles.greeting}>
            Wishing you a joyous holiday season filled with love, peace, and happiness!
          </p>
          <h2 style={styles.offer}>🎁 50% OFF on All Gifts & Greeting Cards 🎁</h2>
          <p style={styles.offerDetails}>
            Celebrate Christmas with our special offer! Get 50% off on all gifts and greeting cards. Hurry,
            this offer is only valid until New Year Day!
          </p>
          <Countdown targetDate={christmasDate} />
        </div>

        {/* Right Side: Images */}
        <div style={styles.rightContent}>
          <img 
            src="img_halloween_cards.png" 
            alt="Christmas Gift 1" 
            style={styles.image} 
          />
          <img 
            src="halloween_cards_2.png" 
            alt="Christmas Gift 2" 
            style={styles.image} 
          />
        </div>
      </section>
    </div>
  );
};

// Inline styles
const styles = {
  page: {
    fontWeight: 'bold',
    color: '#333',
    textAlign: 'center',
    margin: '0',
    padding: '0',
    backgroundColor: '#f0f8ff', // Light blue background for a winter feel
  },
  header: {
    display: 'flex',
    backgroundColor: '#E21F46', // Christmas red color
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    height: '200px',
    color: 'white',
  },
  title: {
    margin: '0',
    fontSize: '36px',
    fontWeight: 'bold',
  },
  content: {
    padding: '30px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    margin: '20px auto',
  },
  leftContent: {
    flex: 1,
    marginRight: '20px', // Space between text and images
  },
  rightContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  greeting: {
    fontSize: '24px',
    color: '#555',
  },
  offer: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#32cd32', // Christmas green
    marginTop: '20px',
  },
  offerDetails: {
    fontSize: '18px',
    color: '#333',
    marginTop: '10px',
  },
  image: {
    maxHeight: '40%',
    width: '100%',
    marginBottom: '20px',
    objectFit: 'cover', // To maintain image aspect ratio without distortion
  },
  ctaButton: {
    backgroundColor: '#ff6600',
    color: 'white',
    fontSize: '18px',
    border: 'none',
    padding: '15px 30px',
    cursor: 'pointer',
    borderRadius: '5px',
    marginTop: '20px',
    textTransform: 'uppercase',
  },
  footer: {
    backgroundColor: '#ff0000', // Christmas red
    color: 'white',
    padding: '10px 0',
    marginTop: '40px',
  },
  footerText: {
    margin: '0',
    fontSize: '16px',
  },
};

export default Christmas;
